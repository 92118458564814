/* ------------------------------------------------
MODULE LOADER:
We only want to load the required to render each
individal page without a lot of overhead.

"Module loader" detects what modules are rendered
a page and loads only the scripts required to ensue
the modules function properly.
------------------------------------------------*/
const modules = document.querySelectorAll('[data-module]');

(async () => {
    for(let i = 0, length = modules.length; i < length; i++){
        let moduleName = modules[i].getAttribute('data-module');

        if (moduleName !== 0) {
            try{
                await import('./modules/'+moduleName+'.js');
            }
            catch(err){
                console.log(moduleName);
                console.log('./modules/'+moduleName+'.js');
                console.error(err)
            }
        }
    }
})();

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}